import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Observable, of } from 'rxjs';
import { JobFile, JobFileContainer } from '@core/models/job-file';
import { SharedState } from '@core/store/shared.state';
import { Select, Store } from '@ngxs/store';
import { JobGroupJobOperation } from '@shared/models/job-group-job-operation.model';
import { Pagination } from '@shared/models/pagination.model';
import { EntityMap } from '@shared/models/types';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'hmt-job-load-list',
  templateUrl: './job-load-list.component.html',
  styleUrls: ['./job-load-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class JobLoadListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('paginator') paginator: MatPaginator;

  @Input() jobs: JobFile[]  = [];
  @Input() tickedJobIdsMap: EntityMap<string, string> = {};
  @Input() title = '';
  @Input() paginationData: Pagination;
  @Input() selectedJobIndex;
  @Input() visibleJobsCount = 7;
  @Input() headerBoarder = true;
  @Input() enableOptions = true;
  @Input() enableMultiSelect = true;
  @Input() logosMap: EntityMap<string, string> = {};
  @Input() loadPercentage$: Observable<EntityMap<string, number>>;

  @Output() selectJobFile: EventEmitter<JobGroupJobOperation> = new EventEmitter();
  @Output() paginate: EventEmitter<PageEvent> = new EventEmitter();
  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() moveJobFIle: EventEmitter<JobGroupJobOperation> = new EventEmitter();
  @Output() terminateJobFIle: EventEmitter<JobGroupJobOperation> = new EventEmitter();
  @Output() renameJobFIle: EventEmitter<JobGroupJobOperation> = new EventEmitter();
  @Output() tickJobFile: EventEmitter<JobGroupJobOperation> = new EventEmitter();
  @Output() getPercentage: EventEmitter<{orgId: string; jobRefId: string; containerIds: string[]}> = new EventEmitter();

  @Select(SharedState.getSkeletonLoaderStatus) loading$: Observable<boolean>;
  jobFileSearchForm: FormGroup;
  unsubscribe: Subject<void> = new Subject();
  numbers = Array.from({length: 10}, (_, i) => i + 1);
  expandedElement: any = null;

  constructor(private formBuilder: FormBuilder, private router: Router, private store$: Store, private route: ActivatedRoute) {
    this.jobFileSearchForm = this.formBuilder.group({
      searchText: ['']
    });
  }

  ngOnInit(): void {
    // Initialize form with URL search parameter
    const searchText = this.route.snapshot.queryParams.innerPageSearchKey || '';
    this.jobFileSearchForm.patchValue({ searchText }, { emitEvent: false });
    this.initForm();
  }

  ngAfterViewInit(): void {
    // Set paginator to URL page if available
    setTimeout(() => {
      if (this.paginator) {
        const pageIndex = parseInt(this.route.snapshot.queryParams.innerPageNumber) || 0;
        const pageSize = parseInt(this.route.snapshot.queryParams.innerPageSize) || 10;
        this.paginator.pageIndex = pageIndex;
        this.paginator.pageSize = pageSize;
      }
    });
  }

  initForm(): void {
    this.jobFileSearchForm.get('searchText')
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged(),
        debounceTime(500),
        tap(searchText => this.search.emit(searchText))
      )
      .subscribe();
  }

  clearSearch(): void {
    this.jobFileSearchForm.patchValue({searchText: ''});
  }

  clearPaginator(): void {
    setTimeout(() => {
      if (this.paginator) {
        this.paginator.pageIndex = 0;
      }
    });
  }

  onSelectJob(job, index): void {
    if (this.expandedElement !== job) {
      this.selectedJobIndex = index;
      this.selectJobFile.emit({job, index});
    }
  }

  onTickJobFile(job, index): void {
    this.tickJobFile.emit({job, index});
  }

  tickChange({checked}, job, index) {
    this.tickJobFile.emit({job, index, checked});
  }

  onPaginate(pageEvent: PageEvent) {
    this.paginate.emit(pageEvent);
  }

  moveTo(job, index): void {
    this.moveJobFIle.emit({job, index});
  }

  terminate(job, index): void {
    this.terminateJobFIle.emit({job, index});
  }

  rename(job, index): void {
    this.renameJobFIle.emit({job, index});
  }

  navigateToJpm(jobFile: JobFile, container: JobFileContainer){
    this.router.navigate(
      ['/control-tower'],
      { queryParams: { jobRefId: jobFile.jobRefId, containerId: container.containerId} });
  }

  navigateToLoad(jobFile: JobFile, container: JobFileContainer) {
    if (container?.jobProgressStatus === 'SCHEDULE_NOTIFIED' ||
      container?.jobProgressStatus === 'COMPLETED' ||
      container?.jobProgressStatus === 'TERMINATED' ||
      container?.jobProgressStatus === 'IN_PROGRESS') {
      this.router.navigate(
        ['/control-tower'],
        { queryParams: { jobRefId: jobFile.jobRefId, containerId: container.containerId} }
      );
    } else if(container?.jobProgressStatus === 'SCHEDULED'){
      this.router.navigate(
        ['/schedule'],
        { queryParams: { jobRefId: jobFile.jobRefId} }
      );
    } else if(container?.jobProgressStatus === 'PLANNING'){
      this.router.navigate(
        ['/wizard/container-management'],
        { queryParams: { jobRefId: jobFile.jobRefId, order:0, containerId: container.containerId} }
      );
    }
  }

  getLoadPercentage(jobFile: JobFile, container: JobFileContainer) {
    return this.loadPercentage$;
  }

  toggleRow(jobFile: JobFile, event: Event) {
    event.stopPropagation();
    this.expandedElement = this.expandedElement === jobFile ? null : jobFile;
    const containerIds = jobFile?.containers.map(c => c.containerId);
    if(this.expandedElement){
      this.getPercentage.emit({orgId: jobFile.orgId, jobRefId: jobFile.jobRefId, containerIds});
    }
  }

  getLoadStatus(jobFile: JobFile, container: JobFileContainer) {
      if(container?.jobProgressStatus === 'TERMINATED'){
        return 'Terminated';
      }
      if(container?.jobProgressStatus === 'COMPLETED'){
        return 'Completed';
      }
      if(container?.jobProgressStatus === 'IN_PROGRESS'){
        return 'In-Progress';
      }
      if(container?.jobProgressStatus === 'SCHEDULE_NOTIFIED'){
        return 'Scheduled-Notified';
      }
      if(container?.jobProgressStatus === 'SCHEDULED'){
        return 'Scheduled';
      }
      if(container?.jobProgressStatus === 'PLANNING'){
        return 'Planning';
      }
  }

  activeCT(container: JobFileContainer) {
    return !(
      container?.jobProgressStatus === 'SCHEDULE_NOTIFIED' ||
      container?.jobProgressStatus === 'COMPLETED' ||
      container?.jobProgressStatus === 'TERMINATED' ||
      container?.jobProgressStatus === 'IN_PROGRESS');
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
