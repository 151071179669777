<div class="jobs-wrapper col">
  <div class="header row va-c" [ngClass]="{'header-boarder': headerBoarder}">
    <div class="title bold"> {{title}} </div>
    <div class="search">
      <form [formGroup]="jobFileSearchForm">
        <mat-form-field appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input formControlName="searchText" matInput placeholder="Search">
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="job-list">
    <mat-list role="list" [ngStyle]="{'height.px': visibleJobsCount * 72}">
      <mat-list-item class="job" role="listitem"
        [ngClass]="{'selected': selectedJobIndex == i, 'expanded': expandedElement === job}"
        *ngFor="let job of jobs; let i = index;"
        (click)="onSelectJob(job, i)">
        <mat-checkbox [hidden]="!enableMultiSelect" (change)="tickChange($event, job, i)" mat-list-icon (click)="$event.stopPropagation();"></mat-checkbox>
        <div class="job-content">
          <div class="job-header row va-c">
            <div class="job-info">
              <div mat-line class="job-title bold">{{job?.jobTitle}} {{job?.createdTimestamp | date}} | {{job?.jobType}}</div>
              <div mat-line class="job-id"> Job Ref Id: {{job?.jobRefId}} </div>
            </div>
            <mat-chip selected class="customer">{{job?.owner?.name}}</mat-chip>
            <div class="logo row va-c ha-c">
              <ng-container *ngIf="job.customer && job.customer.orgId && logosMap[job.customer.orgId]">
                <img [src]="logosMap[job.customer.orgId]">
              </ng-container>
            </div>
            <div class="options" *ngIf="enableOptions">
              <button class="option-btn row va-c ha-c" (click)="$event.stopPropagation()" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Job Operations">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="moveTo(job, i)" mat-menu-item>
                  <span class="uppercase" disabled>Move Job File</span>
                </button>
                <button (click)="rename(job, i)" mat-menu-item>
                  <span class="uppercase" disabled>Rename</span>
                </button>
                <button (click)="terminate(job, i)" mat-menu-item disabled>
                  <span class="uppercase">Terminate</span>
                </button>
              </mat-menu>
            </div>
            <button mat-icon-button (click)="toggleRow(job, $event)">
              <mat-icon>{{expandedElement === job ? 'expand_less' : 'expand_more'}}</mat-icon>
            </button>
          </div>

          <!-- Container List Section -->
          <div class="container-list" [@detailExpand]="expandedElement === job && job.containers.length > 0 ? 'expanded' : 'collapsed'"
               *ngIf="expandedElement === job && job.containers.length > 0">
            <div class="container-item" *ngFor="let container of job.containers">
              <div class="container-header row va-c">
                <a href="javascript:void(0)" class="container-id clickable" (click)="navigateToLoad(job, container)">
                  {{container.containerId}}
                </a>
                <div class="container-progress">
                  <div class="progress-spinner-wrapper">
                    <mat-progress-spinner
                      class="percentage"
                      mode="determinate"
                      [value]="(getLoadPercentage(job, container) | async)[container?.containerId] || 0"
                      color="primary"
                      diameter="45"
                      strokeWidth="3">
                    </mat-progress-spinner>
                    <span class="percentage-value">{{(getLoadPercentage(job, container) | async)[container?.containerId] || 0}}%</span>
                  </div>
                </div>
                <button mat-stroked-button [ngClass]="'job-state-' + getLoadStatus(job, container)?.toLowerCase()" class="status-btn">
                  {{getLoadStatus(job, container) || 'N/A'}}
                </button>
                <button mat-stroked-button color="primary" (click)="navigateToJpm(job, container)" [disabled]="activeCT(container)" class="control-tower-btn">
                  <mat-icon>visibility</mat-icon>
                  CONTROL TOWER
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-list-item>
      <ng-container *ngIf="loading$ | async" class="job-list">
        <mat-list-item class="job" role="listitem"
          *ngFor="let i of numbers">
          <ng-container>
            <div class="skeleton-job-container">
              <hmt-skeleton-loader width="700" height="15"></hmt-skeleton-loader>
              <hmt-skeleton-loader width="700" height="15"></hmt-skeleton-loader>
            </div>
            <div class="skeleton-customer-container">
              <hmt-skeleton-loader width="100" height="25" [styles]="{'border-radius': '15px'}" class="skeleton-customer"></hmt-skeleton-loader>
              <hmt-skeleton-loader width="40" height="40" class="skeleton-logo"></hmt-skeleton-loader>
            </div>
          </ng-container>
        </mat-list-item>
      </ng-container>
    </mat-list>
  </div>
</div>

<div class="full-width row va-c ha-c job-paginator">
  <mat-paginator #paginator (page)="onPaginate($event)"
      [length]="paginationData?.totalElements"
      [pageSize]="paginationData.pageSize"
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page"
      showFirstLastButtons>
  </mat-paginator>
</div>