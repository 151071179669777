import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {
  AddContractedRateToJobFileAction, ChangeLocationOrder, DeleteAllShipmentOrdersJobFile, DeleteOtherReferenceNumber, DeleteShipmentOrderJobFile, DisableValidation,
  DownloadConsolidatedLoadManifest,
  DownloadJobSummary,
  DownloadLoadManifest,
  DownloadPackingListExcelAction,
  DownloadPackingListZipAction,
  FetchCollaborators,
  FinalizeJobFile, GenerateCollaboratorsAsync,
  GenerateContractPDF,
  GenerateJobSummaryPDF,
  GenerateJobSummaryPDFAsync,
  GenerateQuotationPDF,
  GetActivityDefinitionsAction,
  GetConfigAction,
  GetCustomerInfo,
  GetCustomsChannels,
  InviteCollaborators,
  LoadJobFileAction,
  LoadJobSummaryAction,
  LoadRateCards,
  LoadSelectedActivityAction,
  LoadWizardDocumentAction,
  LoadWorkflowDefinitionV2,
  LoadWorkOrderDefinitionsAction,
  NextAction, RemoveCollaborator,
  RemoveWizardAttachment, ResetWizardState,
  SaveDistributionWorkflowDataAction,
  SaveOrderReferenceNumber,
  SaveOtherReferenceNumber,
  SaveWizardRowData,
  SaveWizardVehicleCapacitiesData,
  SendForApprovalJob,
  setOptimizationInfo,
  setRouteOptions,
  UploadWizardAttachments,
  UploadWizardRequiredDocument,
  UseExpresswayAction,
  SaveRemark,
  SaveRemarks,
  DeleteRemark,
  LoadRemarks
} from './wizard.actions';
import {LocationsState} from './locations/locations.state';
import {WizardStateModel} from '../models/state/wizard-state.model';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {DateMap, JobFile} from '@core/models/job-file';
import {SetNotificationAction} from '@core/store/notification/notification.actions';
import {AlertDialogType} from '@shared/models';
import {JobFileService} from '@core/services/job-file.service';
import {of} from 'rxjs';
import {ContainerManagementState} from './container-management/container-management.state';
import {WorkFlowConfigurationService} from '../services/work-flow-configuration.service';
import {JobCompletionStep} from '../models/job-completion-step';
import {Field, MandatoryFieldMap} from '../models/mandatory-field-map.model';
import {StepZeroData} from '../models/step-data.model';
import {QuotationStateModel} from '../../quotation/models/state/quotation-state.model';
import {RateCard} from '@shared/models/rate-card.model';
import {RateCardService} from '@shared/services/rate-card.service';
import {WorkOrderDefinition} from '@control-tower/models/work-order/work-order-definition.model';
import {WorkOrderService} from '@shared/services/work-order.service';
import {CustomerInfo} from '@control-tower/models/customer-info.model';
import {ResourceService} from '../../fuel/services/resource.service';
import {EntityMap} from '@shared/models/types';
import {WorkOrderType} from '@shared/models/enums/work-order-type.enum';
import {CustomsChannel} from '../models/enums/customs-channel.enum';
import {JobSummary} from '../models/job-summary.model';
import {JobSummaryConfig} from '@core/models/job-summary-config';
import {ViewComponentConfig} from '@shared/models/view-component-config.model';
import {JobSummaryConfigService} from '@core/services/job-summary-config.service';
import {environment} from '@env/environment';
import {Property} from '../models/workflow/property';
import {DateProperty} from '../models/workflow/DateProperty';
import {SetSpinner} from '@core/store/shared.actions';
import {JsonSchema} from '../models/workflow/jsonSchema';
import {FileService} from '@control-tower/services/file.service';
import {AttachmentModel} from '../../fuel/models/attachment.model';
import {User} from '@core/models/user.model';
import { AddContainer } from '../models/add-container.model';
import { JobFileServiceModel } from '@core/models/job-file/job-file-service.model';
import { Router } from '@angular/router';
import { LoadManifestService } from '../services/laod-manifest.service';
import { GetContainerWithContainerId } from './container-management/container-management.actions';
import { OptimizationInfo } from '../models/optimization-info.model';
import { RouteOptions } from '../models/route-options.model';

export const getInitialState = (): WizardStateModel => ({
  jobFile: null,
  useExpressWay: false,
  workflowData: {},
  workOrderDefinitions: {},
  workflowConfig: null,
  jobFileCompletionSteps: [],
  mandatoryFieldMap: {},
  rateCards: [],
  activityList: [],
  selectedActivity: null,
  customerInfo: null,
  steps: {},
  customsChannels: [],
  jobSummary: null,
  config: [],
  workflowDefinitionV2: null,
  selectedAttachment: null,
  internalCollaborators: [],
  isFormValid: false,
  metaDataViewModel: {},
  selectedOptimizationInfo: null,
  routeOptions: null
});
export const WizardStateKey = 'wizard';

@State<WizardStateModel>({
  name: 'wizard',
  defaults: getInitialState(),
  children: [LocationsState, ContainerManagementState]
})
@Injectable()
export class WizardState {

  constructor(public store$: Store,
              private jobFileService: JobFileService,
              private rateCardService: RateCardService,
              private workOrderService: WorkOrderService,
              private workflowConfigurationService: WorkFlowConfigurationService,
              private resourceService: ResourceService,
              private configService: JobSummaryConfigService,
              private fileService: FileService,
              private loadManifestService: LoadManifestService,
              private router: Router)
              {}

  @Selector()
  static getJobFile(state: WizardStateModel): JobFile {
    return state.jobFile;
  }

  @Selector()
  static getSelectedAttachment(state: WizardStateModel): AttachmentModel {
    return state.selectedAttachment;
  }

  @Selector()
  static useExpressWay(state: WizardStateModel): boolean {
    return state.useExpressWay;
  }

  @Selector()
  static isFormValid(state: WizardStateModel): boolean {
    return state.isFormValid;
  }

  @Selector()
  static getKeyDates(state: WizardStateModel): DateMap[] {
    return state.jobFile?.dateList || [];
  }

  @Selector()
  static getJobFileCompletionSteps(state: WizardStateModel): JobCompletionStep[] {
    return state.jobFileCompletionSteps;
  }

  @Selector()
  static getJobFileMandatoryFieldsMap(state: WizardStateModel): MandatoryFieldMap {
    return state.mandatoryFieldMap;
  }

  @Selector()
  static getWorkflowData(state: WizardStateModel): StepZeroData {
    return state.workflowData;
  }

  @Selector()
  static isWorkflowDataValid(state: WizardStateModel): boolean {
    // only checking the first three steps, because those are stored in the workflow data
    const fieldMap: Field = Object.values(state.mandatoryFieldMap).slice(0, 3)
      .reduce((prev, curr) => ({...prev, ...curr}), {});
    for (const mandatoryFieldMapStep of Object.keys(fieldMap)) {
      if (!state.workflowData[mandatoryFieldMapStep]) {
        return false;
      }
    }
    return true;
  }

  @Selector()
  static getRateCards(state: QuotationStateModel): RateCard[] {
    return state.rateCards;
  }

  @Selector()
  static getOptionalWorkOrders(state: WizardStateModel): WorkOrderDefinition[] {
    const selectedActivity = state.selectedActivity;
    const optionalWorkOrderList: WorkOrderDefinition[] = [];
    const optionalWorkOrders = selectedActivity?.children
      .filter(workOrderChildren => !workOrderChildren.mandatory);

    optionalWorkOrders?.forEach(workOrder => {
      optionalWorkOrderList.push(state.workOrderDefinitions[workOrder.workOrderId]);
    });
    return optionalWorkOrderList;
  }

  @Selector()
  static getWorkOrdersInActivity(state: WizardStateModel) {
    return Object.keys(state.workOrderDefinitions).map((workOrderDefinitionId: string) => state.workOrderDefinitions[workOrderDefinitionId]);
  }

  @Selector()
  static getWorkOrderDefinitions(state: WizardStateModel): EntityMap<string, WorkOrderDefinition> {
    return state.workOrderDefinitions;
  }

  @Selector()
  static getActivityList(state: WizardStateModel): WorkOrderDefinition[] {
    return Object.values(state.workOrderDefinitions).filter(definition => definition.type === WorkOrderType.ACTIVITY);
  }

  @Selector()
  static getListOfNonValidatedFields(state: WizardStateModel): string[] {
    return [... new Set(Object.values(state.mandatoryFieldMap).slice(0, 3)
      .map(field => Object.keys(field)).flat(1))]
      .filter(field => !state.workflowData[field])
      .map(field => `${field} Required!`);
  }

  @Selector()
  static getCustomerInfo(state: WizardStateModel): CustomerInfo {
    return state.customerInfo;
  }

  @Selector()
  static getCustomsChannels(state: WizardStateModel): CustomsChannel[] {
    return state.customsChannels;
  }

  @Selector()
  static getJobSummary(state: WizardStateModel): JobSummary {
    return state.jobSummary;
  }

  @Selector()
  static getConfig(state: WizardStateModel): ViewComponentConfig[] {
    return state.config;
  }

  @Selector()
  static isServiceLocationsAndTimeValid(state: WizardStateModel): boolean {
    if (state.jobFile?.containers.length === 0) {
      return false;
    }
    return !state.jobFile?.containers.some(container => container?.services?.some((service, index) => index === 0
          ? service.locationId == null || service.services[0].plannedStartDateTime == null
          : service.locationId == null));
  }

  @Selector()
  static getMandatoryFieldsToBeFilled(state: WizardStateModel): Property[]{
    return  state.workflowDefinitionV2.properties
      .filter((property) => property.mandatory && !state.jobFile.propertyMap[property.key]);
  }

  @Selector()
  static getMandatoryDateFieldsToBeFilled(state: WizardStateModel): DateProperty[]{
    const notFilledDates = [];

    state.jobFile.dateList.filter(date => date.date == null)
      .map(date => notFilledDates.push(date.title));

    return state.workflowDefinitionV2.dateConfigurations
      .filter(dateProperty => dateProperty.mandatory && notFilledDates.includes(dateProperty.title));
  }

  @Selector()
  static getWorkflowDefinitionAdditionalActivities(state: WizardStateModel): string[] {
    return state.workflowDefinitionV2.additionalServices;
  }

  @Selector()
  static getWorkflowDefinitionJsonSchema(state: WizardStateModel): JsonSchema {
    return state.workflowDefinitionV2.jsonSchema;
  }

  @Selector()
  static getWizardRowDataMap(state: WizardStateModel): EntityMap<string, string> {
    return state.jobFile.wizardRowData;
  }

  @Selector()
  static getJobFileInternalCollaborator(state: WizardStateModel): User[]{
    return state.internalCollaborators;
  }

  @Selector()
  static getOptimizationInfo(state: WizardStateModel): OptimizationInfo {
    return state.selectedOptimizationInfo;
  }

  @Selector()
  static getRouteOptions(state: WizardStateModel): RouteOptions {
    return state.routeOptions;
  }

  @Action(UseExpresswayAction)
  useExpressway({patchState}: StateContext<WizardStateModel>, {useExpressWay}: UseExpresswayAction) {
    return of(patchState({useExpressWay}));
  }

  @Action(LoadJobFileAction)
  loadJobFile({getState, patchState, dispatch}: StateContext<WizardStateModel>, {jobRefId}: LoadJobFileAction) {
    const {user: {orgId}} = this.store$.selectSnapshot(state => state.auth);
    return this.jobFileService.getJobFileByOrgIdAndJobRefId(orgId, jobRefId)
      .pipe(
        map((jobFile: JobFile) => patchState({jobFile: {...getState().jobFile, ...jobFile}})),
        catchError(err => dispatch(new SetNotificationAction('Error loading job', AlertDialogType.ERROR)))
      );
  }

  // @Action(GenerateJobFileCompletionSteps)
  // generateJobFileCompletionSteps({getState, patchState}: StateContext<WizardStateModel>) {
  //   const {workflowConfig, mandatoryFieldMap, workflowData} = getState();
  //   const jobFileCompletionSteps: JobCompletionStep[] = this.workflowConfigurationService
  //     .generateJobFileCompletionSteps(workflowConfig, mandatoryFieldMap, workflowData);
  //   return patchState({jobFileCompletionSteps});
  // }

  // @Action(ProcessJobFileCompletionSteps)
  // processJobFileCompletionSteps({setState, getState}: StateContext<WizardStateModel>) {
  //   const {mandatoryFieldMap} = getState();
  //   const containers = this.store$.selectSnapshot(ContainerManagementState.getContainers);
  //   const subSteps: StepDetails[] = containers.map((container: AddContainer) => {
  //     return {
  //       title: container.containerId, name: container.containerId, order: 3,
  //       filledMandatory: this.workflowConfigurationService.validateAddContainerFields(container, mandatoryFieldMap)
  //     };
  //   });
  //   return setState(
  //     patch({
  //       jobFileCompletionSteps: updateItem(items => items.title === 'Container Details', patch({subSteps}))
  //     })
  //   );
  // }

  // @Action(GenerateMandatoryFieldMap)
  // generateMandatoryFieldsMap({getState, patchState}: StateContext<WizardStateModel>) {
  //   const {workflowConfig, steps} = getState();
  //   const mandatoryFieldMap: MandatoryFieldMap = this.workflowConfigurationService
  //     .generateMandatoryFieldsMap(workflowConfig);
  //   const mandatoryFieldMapOfLastTwoSteps: MandatoryFieldMap = this.workflowConfigurationService
  //     .generateMandatoryFieldMapAfterStepTwo(steps);
  //   Object.entries(mandatoryFieldMapOfLastTwoSteps).forEach(([key, value]) => {
  //     mandatoryFieldMap[key] = value;
  //   });
  //   return patchState({mandatoryFieldMap});
  // }

  // @Action(FetchWorkFlowData)
  // fetchWorkflowData({dispatch, getState, patchState}: StateContext<WizardStateModel>) {
  //   const {jobFile: {jobRefId, orgId}} = getState();
  //   return this.workflowConfigurationService.getWorkflowDataByOrgIdAndJobRefId(orgId, jobRefId)
  //     .pipe(
  //       filter((workflowDataArray: WorkflowData[]) => workflowDataArray && workflowDataArray.length > 0),
  //       map((workflowDataArray: WorkflowData[]) => workflowDataArray[0].stepData),
  //       filter((stepDataArray: StepData[]) => stepDataArray && stepDataArray.length > 0),
  //       map((stepDataArray: StepData[]) => JSON.parse(stepDataArray[0].data)),
  //       tap((stepZeroData: StepZeroData) => {
  //         patchState({workflowData: stepZeroData});
  //       }),
  //       catchError(err => dispatch(new SetNotificationAction('Error loading Workflow Data', AlertDialogType.ERROR)))
  //     );
  // }

  @Action(LoadRateCards)
  loadRateCards({dispatch, getState, patchState}: StateContext<WizardStateModel>, {}: LoadRateCards) {
    const {jobFile: {orgId, customerId, operationId}} = getState();
    return this.rateCardService.getActiveRateCards([customerId], true, operationId)
      .pipe(
        map(ratesPage => ratesPage.content),
        map(rateCards => patchState({rateCards})), // convert to map here
        catchError(err => dispatch(new SetNotificationAction('Error loading Rate Cards', AlertDialogType.ERROR)))
      );
  }

  @Action(AddContractedRateToJobFileAction)
  addContractedRateToJobFile({getState, patchState, dispatch}: StateContext<WizardStateModel>,
                             {paymentTerm, rateCardId, order, loadRateCard}: AddContractedRateToJobFileAction) {
    const {jobFile} = getState();
    const {user: {firstName, lastName, userId, designation}} = this.store$.selectSnapshot(state => state.auth);
    const generatedBy = `${firstName} ${lastName}#${userId}#${designation}`;
    return this.jobFileService.addContractedRateToJobFile(paymentTerm, jobFile.orgId, jobFile.jobRefId, rateCardId, order, generatedBy, loadRateCard)
      .pipe(
        tap(res => {
          const updatedJobFile = {...jobFile, paymentTerm, rateCardId};
          patchState({
            jobFile: updatedJobFile,
          });
          dispatch(new SetNotificationAction('Adding contracted rate Successfully', AlertDialogType.SUCCESS));
          dispatch(new LoadJobFileAction(jobFile.jobRefId));
          this.router.navigate([`/schedule`], {queryParams: {jobRefId: jobFile.jobRefId}});
        }),
        catchError(err => dispatch(
          [new SetNotificationAction('Error adding contracted rate', AlertDialogType.ERROR), new SetSpinner(false)]))
      );
  }

  @Action(LoadWorkOrderDefinitionsAction)
  loadWorkOrderDefinitions({getState, patchState, dispatch}: StateContext<WizardStateModel>,
                           {workOrderIds}: LoadWorkOrderDefinitionsAction) {
    return this.workOrderService.getWorkOrderDefinitionList(workOrderIds)
      .pipe(
        map((workOrderDefinitions: WorkOrderDefinition[]) => workOrderDefinitions.reduce((workOrderDefinitionsMap, workOrderDefinition: WorkOrderDefinition) => {
            workOrderDefinitionsMap[workOrderDefinition.id] = workOrderDefinition;
            return workOrderDefinitionsMap;
          }, {})),
        tap(workOrderDefinitionsMap => {
          patchState({workOrderDefinitions: workOrderDefinitionsMap});
        }),
        catchError(err => dispatch(new SetNotificationAction('Error loading Work Order Definitions', AlertDialogType.ERROR)))
      );
  }

  // @Action(FetchWorkFlowConfigFromJobFile)
  // loadWorkFlowConfigFromJObFile({getState, dispatch, patchState}: StateContext<WizardStateModel>) {
  //   const {jobFile: {orgId, workflowId}} = getState();
  //   return this.workflowConfigurationService.getWorkflowConfigByOrgIdAndWorkflowId(orgId, workflowId)
  //     .pipe(
  //       map((workflowConfigs: WorkflowConfig[]) => {
  //         const workflowConfig: WorkflowConfig = (workflowConfigs && workflowConfigs[0]) ? workflowConfigs[0] : null;
  //         return patchState({workflowConfig});
  //       }),
  //       catchError(err => dispatch(new SetNotificationAction('Error loading Workflow Configuration', AlertDialogType.ERROR)))
  //     );
  // }

  @Action(GetActivityDefinitionsAction)
  getActivityDefinitionsAction({dispatch, patchState}: StateContext<WizardStateModel>) {
    return this.workflowConfigurationService.getActivityList()
      .pipe(
        map((activities: WorkOrderDefinition[]) => {
          patchState({activityList: activities});
        }),
        catchError(() => dispatch(new SetNotificationAction('Error loading activity definitions', AlertDialogType.ERROR)))
      );
  }

  @Action(LoadSelectedActivityAction)
  loadSelectedActivityAction({dispatch, patchState}: StateContext<WizardStateModel>,
                             {selectedActivity}: LoadSelectedActivityAction) {
    const workOrderIds: string[] = [];
    selectedActivity.children.forEach(child => {
      workOrderIds.push(child.workOrderId);
    });
    patchState({selectedActivity});
    dispatch(new LoadWorkOrderDefinitionsAction(workOrderIds));
  }

  // @Action(SaveActivityAction)
  // saveActivityAction({dispatch, patchState}: StateContext<WizardStateModel>,
  //                    {saveServiceViewModel}: SaveActivityAction) {
  //   return this.workflowConfigurationService.saveActivity()
  //     .pipe(
  //       map((activities: boolean) => {
  //         dispatch(new SetNotificationAction('Successfully save activity', AlertDialogType.SUCCESS));
  //       }),
  //       catchError(() => dispatch(new SetNotificationAction('Error save activity', AlertDialogType.ERROR)))
  //     );
  // }

  @Action(DownloadJobSummary)
  downloadJobSummary({dispatch, patchState, getState}: StateContext<WizardStateModel>, {order}: DownloadJobSummary) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.getJobSummaryPDFFileInfo(orgId, jobRefId, order)
      .pipe(
        mergeMap(fileInfo => this.jobFileService.getPdfPreSIgnedUrl(fileInfo.id)),
        mergeMap(res => this.jobFileService.getPDFFileData(res.url)),
        tap(response => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(response);
          a.download = `Job-Summary-${jobRefId}.pdf`;
          document.body.appendChild(a);
          a.click();
          dispatch(new SetSpinner(false));
        }),
        catchError(err => dispatch(new SetNotificationAction('Error Downloading Summary PDF', AlertDialogType.ERROR)))
      );
  }

  @Action(GenerateJobSummaryPDF)
  generateJobSummaryPDF({dispatch, getState}: StateContext<WizardStateModel>, {order}: DownloadJobSummary) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.downloadJobSummary(orgId, jobRefId, order)
      .pipe(
        catchError(err => dispatch(new SetNotificationAction('Error Submitting job Summary PDF Generation Action', AlertDialogType.ERROR)))
      );
  }

  @Action(GenerateJobSummaryPDFAsync)
  generateJobSummaryPDFAsync({dispatch, getState}: StateContext<WizardStateModel>, {order}: DownloadJobSummary) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.generateJobSummaryAsync(orgId, jobRefId, order)
      .pipe(
        tap(r => {
          console.log('Job Summary pdf generated successfully', r);
        }),
        catchError(err => {
          console.log('Job Summary pdf generated failed', err);
          return dispatch(new SetNotificationAction('Error Submitting job Summary PDF Async Generation Action', AlertDialogType.ERROR));
        })
      );
  }

  @Action(GenerateCollaboratorsAsync)
  generateCollaboratorsAsync({dispatch, getState}: StateContext<WizardStateModel>, {order}: GenerateCollaboratorsAsync) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.generateCollaboratorsAsync(orgId, jobRefId, order)
      .pipe(
        tap(r => {
          console.log('Collaborators generated successfully', r);
        }),
        catchError(err => {
          console.log('Collaborators generated failed', err);
          return dispatch(new SetNotificationAction('Error Generating collaborators Async Action', AlertDialogType.ERROR));
        })
      );
  }

  @Action(GenerateQuotationPDF)
  generateQuotationPDF({dispatch, getState}: StateContext<WizardStateModel>, {order}: GenerateQuotationPDF) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.generateQuotationPDF(orgId, jobRefId, order)
      .pipe(
        tap(r => {
          console.log('Quotation pdf generated successfully', r);
        }),
        catchError(err => {
          console.log('Quotation pdf generated failed', err);
          return dispatch(new SetNotificationAction('Error Submitting job Quotation Generation Action', AlertDialogType.ERROR));
        })
      );
  }

  @Action(GenerateContractPDF)
  generateContractPDF({dispatch, getState}: StateContext<WizardStateModel>, {order}: GenerateContractPDF) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.generateContractPDF(orgId, jobRefId, order)
      .pipe(
        tap(r => {
          console.log('Contract pdf generated successfully', r);
        }),
        catchError(err => {
          console.log('Contract pdf generated failed', err);
          return dispatch(new SetNotificationAction('Error Submitting Contract PDF Generation Action', AlertDialogType.ERROR));
        })
      );
  }

  @Action(SendForApprovalJob)
  sendForApprovalJob({dispatch, patchState, getState}: StateContext<WizardStateModel>, {order}: SendForApprovalJob) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.jobSendForApproval(orgId, jobRefId, order)
      .pipe(
        tap(() => {
          dispatch(new SetNotificationAction('Successfully Job Send for approval', AlertDialogType.SUCCESS));
          //window.location.href = `${environment.haulmatic.baseUrl}/fleet-management/jobs`;
          this.router.navigate(['dashboard']);
        }),
        catchError(err => dispatch(new SetNotificationAction('Error Send for approval job', AlertDialogType.ERROR)))
      );
  }

  @Action(GetCustomerInfo)
  getCustomerInfo({dispatch, patchState, getState}: StateContext<WizardStateModel>) {
    const {jobFile} = getState();
    return this.jobFileService.getCustomerInfoByCustomerId(jobFile.customerId)
      .pipe(
        map(customerInfo => patchState({customerInfo})),
        catchError(err => dispatch(new SetNotificationAction('Error getting customer info', AlertDialogType.ERROR)))
      );
  }

  // @Action(FetchWorkFlowSteps)
  // fetchWorkFlowComponents({getState, dispatch, patchState}: StateContext<WizardStateModel>) {
  //   const {jobFile: {workflowId}} = getState();
  //   return this.workflowConfigurationService.getWorkFlowSteps(workflowId)
  //     .pipe(
  //       map((steps: EntityMap<number, WorkFlowComponent[]>) => patchState({steps})),
  //       catchError(err => dispatch(new SetNotificationAction('Error occurred while retrieving Workflow Components', AlertDialogType.ERROR)))
  //     );
  // }

  @Action(GetCustomsChannels)
  getCustomsChannels({getState, dispatch, patchState}: StateContext<WizardStateModel>) {
    const {jobFile: {workflowId}} = getState();
    return this.workflowConfigurationService.getCustomsChannels(workflowId)
      .pipe(
        map((customsChannelsList: CustomsChannel[]) => {
          patchState({customsChannels: customsChannelsList});
        }),
        catchError(err => dispatch(new SetNotificationAction('Error occurred while retrieving Customs Channels', AlertDialogType.ERROR)))
      );
  }

  @Action(LoadJobSummaryAction)
  loadJobSummary({getState, patchState, dispatch}: StateContext<WizardStateModel>, {jobRefId, order}: LoadJobSummaryAction) {
    const {user: {orgId}} = this.store$.selectSnapshot(state => state.auth);
    return this.jobFileService.getJobSummary(orgId, jobRefId, order)
      .pipe(
        map((jobSummary: JobSummary) => patchState({jobSummary: {...getState().jobSummary, ...jobSummary}})),
        catchError(err => dispatch(new SetNotificationAction('Error loading job summary', AlertDialogType.ERROR)))
      );
  }

  @Action(FinalizeJobFile)
  finalizeJobFile({dispatch, patchState, getState}: StateContext<WizardStateModel>, {order}: FinalizeJobFile) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.finalizeJobFile(orgId, jobRefId, order)
      .pipe(
        map((jobFile: JobFile) => patchState({jobFile: {...getState().jobFile, ...jobFile}})),
        catchError(err => dispatch(new SetNotificationAction('Error finalizing job file', AlertDialogType.ERROR)))
      );
  }

  @Action(GetConfigAction)
  getConfig({patchState, dispatch}: StateContext<WizardStateModel>, {workflowId, configType}: GetConfigAction) {
    return this.configService.getConfig(workflowId, configType)
      .pipe(
        map((data: JobSummaryConfig) => patchState({config: data.config})),
        catchError(err => dispatch(new SetNotificationAction('Error loading config', AlertDialogType.ERROR)))
      );
  }

  @Action(LoadWorkflowDefinitionV2)
  loadWorkFlowDefinition({getState, patchState, dispatch}: StateContext<WizardStateModel>){
    const {jobFile: {workflowId}} = getState();
    return this.workflowConfigurationService.getWorkflowDefinitionV2(workflowId)
      .pipe(
        map((workflowDefinitionV2) => patchState({workflowDefinitionV2})),
        catchError(err => dispatch(new SetNotificationAction('Error loading workflow definitionV2', AlertDialogType.ERROR)))
      );
  }

  @Action(NextAction)
  clickNextButton({dispatch, patchState, getState}: StateContext<WizardStateModel>, {}: NextAction) {
    return of(true);
  }

  @Action(DisableValidation)
  disableValidation({patchState, getState}: StateContext<WizardStateModel>,  {valid}: DisableValidation) {
    return of(patchState({
      isFormValid: valid
    }));
  }

  @Action(SaveWizardRowData)
  saveWizardRowData({dispatch, patchState, getState}: StateContext<WizardStateModel>, {rowData}: SaveWizardRowData) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.saveWizardRowData(orgId, jobRefId, rowData)
      .pipe(
        map((jobFile) => {
          patchState({jobFile});
          if (jobFile?.warningMessage) {
            return dispatch(new SetNotificationAction(jobFile?.warningMessage, AlertDialogType.WARNING, {duration: 45000}));
          }
        }),
        catchError(err => dispatch(new SetNotificationAction(err.error?.message ? err.error?.message : 'Error saving wizard Data', AlertDialogType.ERROR, err.error?.message ? {duration: err.error?.message ? 30000 : 4000} :  null)))
      );
  }


  @Action(SaveWizardVehicleCapacitiesData)
  saveVehicleCapacities({dispatch, patchState, getState}: StateContext<WizardStateModel>, {vehicleCapacities}: SaveWizardVehicleCapacitiesData) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.saveVehicleCapacitiesInWizard(orgId, jobRefId, vehicleCapacities)
      .pipe(
        map((jobFile) => patchState({jobFile})),
        catchError(err => dispatch(new SetNotificationAction('Error saving vehicle Capacities Data', AlertDialogType.ERROR)))
      );
  }

  @Action(SaveDistributionWorkflowDataAction)
  saveDistributionWorkflowData({dispatch, patchState, getState}: StateContext<WizardStateModel>, {distributionWorkflowData}: SaveDistributionWorkflowDataAction) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.saveDistributionWorkflowData(orgId, jobRefId, distributionWorkflowData)
      .pipe(
        tap((jobFile) => console.log('Saved Distribution Workflow data successfully')),
        catchError(err => dispatch(new SetNotificationAction('Error saving distribution workflow Data', AlertDialogType.ERROR)))
      );
  }

  @Action(DownloadPackingListExcelAction)
  downloadPackingListExcel({dispatch, getState}: StateContext<WizardStateModel>, { containerId }: DownloadPackingListExcelAction) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.getPackingListExcel(orgId, jobRefId, containerId)
      .pipe(
        tap((response: any) => {
          const blob = response.blob;
          const headers = response.headers;
          const contentDisposition = headers.get('Content-Disposition');
          let filename = `packing-list-${containerId}.xlsx`;
          if (contentDisposition) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          dispatch(new SetSpinner(false));
        }),
        catchError(() => dispatch(new SetNotificationAction('Error Downloading Packing List', AlertDialogType.ERROR)))
    );
  }

  @Action(DownloadPackingListZipAction)
  downloadAllPackingListsAsZip({dispatch, getState}: StateContext<WizardStateModel>, {}: DownloadPackingListExcelAction) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.getAllPackingListsZip(orgId, jobRefId)
      .pipe(
        tap((response: Blob) => {
          const fileElement = document.createElement('a');
          fileElement.href = URL.createObjectURL(response);
          fileElement.download = `packing-lists-${jobRefId}.zip`;
          document.body.appendChild(fileElement);
          fileElement.click();
          dispatch(new SetSpinner(false));
        }),
        catchError(() => dispatch(new SetNotificationAction('Error Downloading Packing Lists', AlertDialogType.ERROR)))
    );
  }

  @Action(UploadWizardAttachments)
  uploadWizardAttachments({getState, dispatch}: StateContext<WizardStateModel>, {files, path}: UploadWizardAttachments) {
    const {jobFile: {orgId, jobRefId}} = getState();
    const {workflowDefinitionV2: {jsonSchema: {properties}}} = this.store$.selectSnapshot(state => state.wizard);
    const url = this.jobFileService.modifyUrlReplacingParameter(properties[path].dataSourceUrl, orgId, jobRefId);
    return this.jobFileService.uploadWizardAttachments(files, url)
      .pipe(
        mergeMap(() => dispatch(new SetNotificationAction('Successfully file uploaded', AlertDialogType.SUCCESS))),
        catchError(error => dispatch(new SetNotificationAction('Error Uploading File', AlertDialogType.ERROR)))
      );
  }

  @Action(RemoveWizardAttachment)
  removeWizardAttachments({getState, dispatch}: StateContext<WizardStateModel>, {attachmentId}: RemoveWizardAttachment) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.removeWizardAttachment(orgId, jobRefId, attachmentId)
      .pipe(
        mergeMap(() => dispatch(new SetNotificationAction('Successfully remove attachment', AlertDialogType.SUCCESS))),
        catchError(error => dispatch(new SetNotificationAction('Error removing attachment', AlertDialogType.ERROR)))
      );
  }

  @Action(UploadWizardRequiredDocument)
  uploadWizardRequiredDocument({getState, dispatch}: StateContext<WizardStateModel>, {file, path, type}: UploadWizardRequiredDocument) {
    const {jobFile: {orgId, jobRefId}} = getState();
    const {workflowDefinitionV2: {jsonSchema: {properties}}} = this.store$.selectSnapshot(state => state.wizard);
    const url = this.jobFileService.modifyUploadRequiredDocumentApiUrl(properties[path].dataSourceUrl, orgId, jobRefId, type);
    return this.jobFileService.uploadWizardRequiredDocument(file, url)
      .pipe(
        mergeMap(() => dispatch(new SetNotificationAction('Successfully file uploaded', AlertDialogType.SUCCESS))),
        catchError(error => dispatch(new SetNotificationAction('Error Uploading File', AlertDialogType.ERROR)))
      );
  }

  @Action(LoadWizardDocumentAction)
  loadFileUrlAction({ patchState, getState, dispatch }: StateContext<WizardStateModel>,{ fileId }: LoadWizardDocumentAction) {
    patchState({
      selectedAttachment: null
    });
    return this.fileService.fetchDocumentUrl(fileId).pipe(
      tap((response) => patchState({
          selectedAttachment: response
        })),
      catchError(err => dispatch(new SetNotificationAction('Error loading wizard document', AlertDialogType.ERROR)))
    );
  }

  @Action(FetchCollaborators)
  fetchCollaborators({ patchState, getState, dispatch }: StateContext<WizardStateModel>, {scopes, searchText}: FetchCollaborators) {
    const scopesForCollaborators = ['create:job-file', 'approve:job-file'];
    return this.jobFileService.fetchUsersByScopes(
      scopes && scopes.length > 0 ? scopes : scopesForCollaborators,
      0,
      20,
      searchText
    )
      .pipe(
        map(res => {
          if (getState().jobFile.collaborators) {
            const collaborators = getState().jobFile.collaborators.reduce((prev, curr) => {
              prev[curr.user] = curr;
              return prev;
            }, {});
            return patchState({
              internalCollaborators: res.content.filter(user => !collaborators[user.id])
            });
          } else {
            return patchState({
              internalCollaborators: res.content
            });
          }
        }),
        catchError((_) => dispatch(new SetNotificationAction('Internal Collaborators Loading Failed', AlertDialogType.ERROR)))
      );
  }

  @Action(InviteCollaborators)
  inviteCollaborators({ patchState, getState, dispatch }: StateContext<WizardStateModel>, {collaboratorsInvitationModel}: InviteCollaborators) {
    const {jobFile: {orgId, jobRefId}} = getState();
    collaboratorsInvitationModel.jobRefId = jobRefId;
    collaboratorsInvitationModel.orgId = orgId;
    return this.jobFileService.inviteCollaborators(collaboratorsInvitationModel)
      .pipe(
        map((jobFile) => patchState({jobFile})),
        catchError(err => dispatch(new SetNotificationAction('Error inviting collaborators', AlertDialogType.ERROR)))
      );
  }

  @Action(RemoveCollaborator)
  removeCollaborator({ patchState, getState, dispatch }: StateContext<WizardStateModel>, {collaboratorId}: RemoveCollaborator) {
    const {jobFile: {orgId, jobRefId}} = getState();
    return this.jobFileService.removeCollaborator(orgId, jobRefId, collaboratorId)
      .pipe(
        map((jobFile) => patchState({jobFile})),
        mergeMap(() => dispatch(new SetNotificationAction('Successfully Remove Collaborator', AlertDialogType.SUCCESS))),
        catchError(err => dispatch(new SetNotificationAction('Error removing collaborator', AlertDialogType.ERROR)))
      );
  }

  @Action(ResetWizardState)
  resetState({ patchState }: StateContext<WizardStateModel>) {
    return patchState(getInitialState());
  }

    @Action(DeleteShipmentOrderJobFile)
  deleteShipmentOrderSpecFromJobFile({patchState, getState, dispatch}:
    StateContext<WizardStateModel>,
                                     { shipmentOrderId }: DeleteShipmentOrderJobFile
  ) {
    const {jobFile} = getState();
    return this.jobFileService.deleteShipmentOrderSpecFromJobFile(jobFile?.jobRefId, shipmentOrderId).pipe(
      mergeMap(() => dispatch(new SetNotificationAction('Successfully delete shipment order', AlertDialogType.SUCCESS))),
      catchError(() => dispatch(new SetNotificationAction('Error deleting shipment order', AlertDialogType.ERROR)))
    );
  }

  @Action(DeleteAllShipmentOrdersJobFile)
  deleteAllShipmentOrderSpecsFromJobFIle({getState, dispatch}: StateContext<WizardStateModel>) {
    const {jobFile} = getState();
    return this.jobFileService.deleteAllShipmentOrderSpecsFromJobFile(jobFile?.jobRefId).pipe(
      tap(res=>{
        dispatch([new LoadJobFileAction(jobFile.jobRefId), new SetSpinner(false)]);
      }),
      mergeMap(() => dispatch(new SetNotificationAction('Successfully deleted shipment orders', AlertDialogType.SUCCESS))),
      catchError(() => dispatch(new SetNotificationAction('Error deleting shipment orders', AlertDialogType.ERROR)))
    );
  }

  @Action(ChangeLocationOrder)
  changeLocationOrder({patchState, getState, dispatch}:
    StateContext<WizardStateModel>,
                      { loadId, currIndex, prevIndex }: ChangeLocationOrder
  ) {
    const {containers} = this.store$.selectSnapshot(state => state.wizard.containerManagement);
    const {jobFile} = getState();
    const selectedContainer: AddContainer = containers[loadId];
    if (selectedContainer) {
      const services: JobFileServiceModel[] = selectedContainer?.services ?? [];
      const locIds: string [] = services.map(service => service.locationId);
      if (services && services.length > 0) {
        const cutOut = locIds.splice(prevIndex, 1)[0];
        locIds.splice(currIndex, 0, cutOut);
      }
      return this.jobFileService.reArrangeLocationServices(jobFile.orgId, jobFile.jobRefId, loadId, locIds, prevIndex, currIndex)
      .pipe(
        switchMap(() => dispatch(new LoadJobFileAction(jobFile.jobRefId))),
        mergeMap(() => dispatch(new SetNotificationAction('Location moved successfully', AlertDialogType.SUCCESS))),
        catchError(() => {
          console.log('Error moving location');
          dispatch(new SetSpinner(false));
          return dispatch(new SetNotificationAction('Error moving location', AlertDialogType.ERROR));
        })
      );
    }
  }

  @Action(DownloadConsolidatedLoadManifest)
  downloadConsolidatedLoadManifest({ patchState, getState, dispatch }:
    StateContext<WizardStateModel>,
    { orgId, jobRefId }: DownloadConsolidatedLoadManifest
  ) {
    return this.loadManifestService.getConsolidatedLoadManifest(orgId, jobRefId)
      .pipe(
        tap((res: any) => {
          const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = 'CONSOLIDATED LOAD MANIFEST - ' + jobRefId + '.xlsx';
          // start download
          a.click();
          a.remove();
        }),
        mergeMap(_ => dispatch(new SetNotificationAction('Successfully Downloaded Consolidated Load Manifest', AlertDialogType.SUCCESS))),
        catchError(() => dispatch(new SetNotificationAction('Error Occurred When Downloading Consolidated Load Manifest', AlertDialogType.ERROR))),

      );
  }

  @Action(DownloadLoadManifest)
  downloadLoadManifest({ patchState, getState, dispatch }:
    StateContext<WizardStateModel>,
    { orgId, jobRefId }: DownloadLoadManifest
  ) {
    return this.loadManifestService.getLoadManifestsZip(orgId, jobRefId)
      .pipe(
        tap((res: any) => {
          const blob = new Blob([res], { type: 'application/zip' });
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = 'LOAD MANIFESTS - ' + jobRefId + '.zip';
          // start download
          a.click();
          a.remove();
        }),
        mergeMap(_ => dispatch(new SetNotificationAction('Successfully Downloaded Load Manifests Zip', AlertDialogType.SUCCESS))),
        catchError(() => dispatch(new SetNotificationAction('Error Occurred When Downloading Load Manifests Zip', AlertDialogType.ERROR))),

      );
  }

  @Action(SaveOtherReferenceNumber)
  saveOtherReferenceNumber({dispatch, patchState, getState}: StateContext<JobFileServiceModel>, {orgId, jobRefId, containerId, locationId, serviceId, refNumbers, workOrderId}: SaveOtherReferenceNumber) {
    return this.jobFileService.saveOtherReferenceNumber(orgId, jobRefId, containerId, locationId, serviceId, workOrderId, refNumbers).pipe(
      tap(res=>{
        dispatch(new GetContainerWithContainerId(containerId));
      }),
      mergeMap(() => dispatch(new SetNotificationAction('Successfully added other order reference number', AlertDialogType.SUCCESS))),
      catchError(() => dispatch(new SetNotificationAction('Error creating other order reference number', AlertDialogType.ERROR)))
    );
  }

  @Action(SaveOrderReferenceNumber)
  saveOrderReferenceNumber({dispatch, patchState, getState}: StateContext<JobFileServiceModel>, {orgId, jobRefId, containerId, locationId, serviceId, refNumbers, workOrderId}: SaveOrderReferenceNumber) {
    return this.jobFileService.saveOrderReferenceNumber(orgId, jobRefId, containerId, locationId, serviceId, workOrderId, refNumbers).pipe(
      mergeMap(() => dispatch(new SetNotificationAction('Successfully added order reference number', AlertDialogType.SUCCESS))),
      catchError(() => dispatch(new SetNotificationAction('Error creating order reference number', AlertDialogType.ERROR)))
    );
  }

  @Action(DeleteOtherReferenceNumber)
  deleteOtherReferenceNumber({dispatch, patchState, getState}: StateContext<JobFileServiceModel>, {orgId, jobRefId, containerId, locationId, serviceId, refNumber, workOrderId}: DeleteOtherReferenceNumber) {
    if(refNumber.length>0){
    return this.jobFileService.deleteOtherReferenceNumber(orgId, jobRefId, containerId, locationId, serviceId, workOrderId, refNumber).pipe(
      tap(res=>{
        dispatch(new GetContainerWithContainerId(containerId));
      }),
      mergeMap(() => dispatch(new SetNotificationAction('Successfully deleted other order reference number', AlertDialogType.SUCCESS))),
      catchError(() => dispatch(new SetNotificationAction('Error deleting other order reference number', AlertDialogType.ERROR)))
    );
   }
  }


  @Action(setOptimizationInfo)
  setOptimizationInfo({ patchState }: StateContext<WizardStateModel>, { selectedOptimizationInfo }: setOptimizationInfo) {
    return of(patchState({ selectedOptimizationInfo }));
  }

  @Action(setRouteOptions)
  setRouteOptions({ patchState }: StateContext<WizardStateModel>, { routeOptions }: setRouteOptions) {
    return of(patchState({ routeOptions }));
  }

  @Action(SaveRemark)
  saveRemark({dispatch}: StateContext<WizardStateModel>, {orgId, jobRefId, containerId, remark}: SaveRemark) {
    return this.jobFileService.saveRemark(orgId, jobRefId, containerId, remark).pipe(
      tap(() => {
        dispatch(new GetContainerWithContainerId(containerId));
      }),
      mergeMap(() => dispatch(new SetNotificationAction('Successfully added remark', AlertDialogType.SUCCESS))),
      catchError(() => dispatch(new SetNotificationAction('Error adding remark', AlertDialogType.ERROR)))
    );
  }

  @Action(SaveRemarks)
  saveRemarks({dispatch}: StateContext<WizardStateModel>, {orgId, jobRefId, containerId, remarks}: SaveRemarks) {
    return this.jobFileService.saveRemarks(orgId, jobRefId, containerId, remarks).pipe(
      tap(() => {
        dispatch(new GetContainerWithContainerId(containerId));
      }),
      mergeMap(() => dispatch(new SetNotificationAction('Successfully added remarks', AlertDialogType.SUCCESS))),
      catchError(() => dispatch(new SetNotificationAction('Error adding remarks', AlertDialogType.ERROR)))
    );
  }

  @Action(DeleteRemark)
  deleteRemark({dispatch}: StateContext<WizardStateModel>, {orgId, jobRefId, containerId, index}: DeleteRemark) {
    return this.jobFileService.deleteRemark(orgId, jobRefId, containerId, [index]).pipe(
      tap(() => {
        dispatch(new GetContainerWithContainerId(containerId));
      }),
      mergeMap(() => dispatch(new SetNotificationAction('Successfully deleted remark', AlertDialogType.SUCCESS))),
      catchError(() => dispatch(new SetNotificationAction('Error deleting remark', AlertDialogType.ERROR)))
    );
  }

  @Action(LoadRemarks)
  loadRemarks({dispatch}: StateContext<WizardStateModel>, {orgId, jobRefId, containerId}: LoadRemarks) {
    return this.jobFileService.getRemarks(orgId, jobRefId, containerId).pipe(
      catchError(() => dispatch(new SetNotificationAction('Error loading remarks', AlertDialogType.ERROR)))
    );
  }
}
