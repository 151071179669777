import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hmt-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() width: string;
  @Input() height: string;
  @Input() styles: { [key: string]: string } = {};


  ngOnInit() {
  }

  getMyStyles() {
    const myStyles = {
      'width.px': this.width ? this.width : '',
      'height.px': this.height ? this.height : '',
      ...this.styles
    };
    return myStyles;
  }
}
