import {Injectable} from '@angular/core';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import {SharedStateModel} from '@core/models/store/shared-state.model';
import {CostDescriptionModel} from '@core/models/cost-description.model';
import {
  GetVehicleCategories,
  LoadCountryById,
  LoadOrgById,
  ResetSharedState,
  SearchCost,
  SetSkeletonLoader,
  SetSpinner
} from '@core/store/shared.actions';
import {CostService} from '@core/services/cost.service';
import {catchError, map, tap} from 'rxjs/operators';
import {SetNotificationAction} from '@core/store/notification/notification.actions';
import {AlertDialogType} from '@shared/models';
import { OrganizationService } from 'app/modules/organization/services/organization.service';
import { DashboardWidgetConfig, NavigationConfig, Organization } from 'app/modules/organization/models/organization';
import { Country } from '@shared/models/country.model';
import {
  ContractManagementStateModel
} from "../../modules/contract-management/models/state/contract-management-state.model";
import {VehicleCategory} from "@shared/models/vehicle-category.model";

const defaults: SharedStateModel = {
  costs: [],
  spinner: false,
  country: null,
  organization: null,
  spinnerMessage: '',
  vehicleCategories: [],
  skeletonLoading: true
};

@State<SharedStateModel>({
  name: 'shared',
  defaults
})
@Injectable()
export class SharedState {

  constructor(private costService: CostService,
              private organizationService: OrganizationService) {
  }

  @Selector()
  static searchCosts(state: SharedStateModel): CostDescriptionModel[] {
    return state.costs;
  }

  @Selector()
  static getSpinnerStatus(state: SharedStateModel): {spinner: boolean, spinnerMessage: string} {
    return {spinner: state.spinner, spinnerMessage: state.spinnerMessage};
  }

  @Selector()
  static getUserOrg(state: SharedStateModel): Organization {
    return state.organization;
  }


  @Selector()
  static getNavigationConfig(state: SharedStateModel): NavigationConfig[] {
    return state.organization?.orgConfig?.navigationConfig || [];
  }

  @Selector()
  static getDashboardConfig(state: SharedStateModel): DashboardWidgetConfig[] {
    return state.organization?.orgConfig?.dashboardWidgetsConfig || [];
  }

  @Selector()
  static getUserCountry(state: SharedStateModel): Country {
    return state.country;
  }

  @Selector()
  static getVehicleCategories(state: SharedStateModel): VehicleCategory[] {
    return state.vehicleCategories;
  }

  @Selector()
  static getSkeletonLoaderStatus(state: SharedStateModel): boolean {
    return state.skeletonLoading;
  }

  @Action(SearchCost)
  searchCost({patchState, dispatch}: StateContext<SharedStateModel>, {keyword}: SearchCost) {
    return this.costService.searchCost(keyword)
      .pipe(
        map((costs: CostDescriptionModel[]) => patchState({costs})),
        catchError(err => dispatch(new SetNotificationAction('Error loading cost', AlertDialogType.ERROR)))
      );
  }

  @Action(SetSpinner)
  setSpinner({patchState}: StateContext<SharedStateModel>, {isEnable, message}: SetSpinner) {
    return patchState({spinner: isEnable, spinnerMessage: message});
  }

  @Action(LoadOrgById)
  loadUserOrg({patchState, dispatch}: StateContext<SharedStateModel>, {orgId}: LoadOrgById) {
    return this.organizationService.getOrganization(orgId)
      .pipe(
        map(organization => patchState({organization})),
        catchError(_ => dispatch(new SetNotificationAction('Unable to load User Organization',
          AlertDialogType.ERROR)))
      );
  }

  @Action(LoadCountryById)
  loadUserOrgCountry({patchState, dispatch, getState}: StateContext<SharedStateModel>, {countryId}: LoadCountryById) {
    const organization = getState().organization;
    return this.organizationService.fetchCountryById(countryId ?? organization?.countryId)
      .pipe(
        map(country => patchState({country})),
        catchError(_ => dispatch(new SetNotificationAction('Unable to load User org Country',
          AlertDialogType.ERROR)))
      );
  }

  @Action(ResetSharedState)
  resetState({setState}: StateContext<SharedStateModel>) {
    return setState(defaults);
  }

  @Action(GetVehicleCategories)
  getVehicleCategories({dispatch, patchState}: StateContext<SharedStateModel>,
                       {}: GetVehicleCategories) {
    return this.organizationService.getVehicleCategories()
      .pipe(
        tap((vehicleCategories) => {
          patchState({vehicleCategories});
        }),
        catchError(() => dispatch(new SetNotificationAction('Failed fetch vehicle categories', AlertDialogType.ERROR)))
      );
  }

  @Action(SetSkeletonLoader)
  setSkeletonLoader({patchState}: StateContext<SharedStateModel>, {isEnable}: SetSkeletonLoader) {
    return patchState({skeletonLoading: isEnable});
  }
}
