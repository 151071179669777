import { JobFile, JobFilePaginationRequest } from "@core/models/job-file";
import { JobFileCreationDTO } from "@shared/models/job-file-creation.model";
import { TaskFeedJob } from "@shared/models/task-feed-job.model";
import { JobGroup } from "../models/job-group.model";

export const JOB_GROUP_ACTION_PREFIX = '[Job Group]';

export class CreateJobGroup {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Create Job Group`;
  constructor(public name: string) {}
}

export class UpdateJobGroup {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Edit Job Group`;
  constructor(public jg: JobGroup) {}
}

export class FetchJobGroups {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Fetch Job Groups`;
  constructor(public pageNumber = 0, public searchKey?: string) {}
}

export class SelectJobGroup {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Select Job Group`;
  constructor(public id: string) {}
}

export class FetchJobFiles {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Fetch Job Files`;
  constructor(public pageNumber = 0, public searchKey?: string) {}
}

export class FetchJobFilesByGroupId {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Fetch Job Files By Group ID`;
  constructor(
    public jobGroupId: string | null, 
    public pageNumber = 0, 
    public pageSize = 10,
    public searchKey?: string
  ) {}
}


export class CopyLoadedJobsGroupsDataToJobFileCreationJobGroupsData {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Copy loaded Job Groups to Create Job File Job Group Map`;
  constructor() {}
}

export class SearchJobGroupsForJobFileCreation {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Fetch Job Files For Job File Creation`;
  constructor(public searchText?: string, public selectFirstResult = false) {}
}

export class CreateJobFile {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Creation Job File`;
  constructor(public jobFileCreationModel: JobFileCreationDTO) {}
}
export class FetchInternalCollaborators {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Load Internal Collaborators`;
  constructor(public scopes?: string[], public searchText?: string) {}
}

export class NavigateToJobFile {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Navigate To Job File`;
  constructor(public jobFileCreationModel: JobFileCreationDTO) {}
}

export class ProcessOrgLogoUrl {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Process Org Logo Url`;
}

export class FetchJobFileStatus {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Fetch Job File Status`;
  constructor(public taskFeedJob: TaskFeedJob | JobFile) {}
}

export class NavigateToModule {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Navigate To Module`;
  constructor(public taskFeedJob: TaskFeedJob, public selectedJob?: TaskFeedJob | JobFile) {}
}

export class MoveJobFileToGroup {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Move Job File To Group`;
  constructor(public jobRefId: string, public newJobGroupId: string) {}
}

export class TickJobFile {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Tick Job File`;
  constructor(public jobFile: JobFile, public ticked: boolean) {}
}

export class GetWorkOrderCompletionPercentage {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Get Work Order Completion Percentage`;
  constructor(public orgId: string, public jobRefId: string, public containerIds: string[]) {}
}

export class SetJobGroupSearchKey {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Set Search Key`;
  constructor(public searchKey: string) {}
}

export class SetSelectedJobGroupForDashboardAfterCreation {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Set Selected Job Group For Dashboard After Creation`;
  constructor(public id: string, public jobGroup: JobGroup) {}
}

export class DeleteJobGroup {
  static readonly type = `${JOB_GROUP_ACTION_PREFIX} Delete Job Group`;
  constructor(public jobGroupId: string) {}
}
