export const SNACK_BAR_DURATION = 5000; // in milliseconds
export const SPACE_BETWEEN_CONTAINER_CARDS = 5; // in px
export const color = {
  FFFFFF: '#FFFFFF',
  ECF4FF: '#ECF4FF',
  ffb4b3: '#ffb4b3',
  D3D3D3: '#D3D3D3',
  ff8e54: '#ff8e54'
};
// attachment related constants
export const UPLOAD_FILE_MAXIMUM_SIZE = 20971520;
export const MINIMUM_IMAGE_HEIGHT = 100;
export const MINIMUM_IMAGE_WIDTH = 200;
export const ATTACH_IMAGE = 'Attach';
export const RE_ATTACH_IMAGE = 'Re-Attach';
export const RE_UPLOAD_IMAGE = 'Re-Upload';
export const IMAGE_DIMENSION_VALIDATION = '100x200 or larger Recommended';
export const FILE_MAX_SIZE_EXCEED = 'file is too large';
// vertical related constants
export const COMMON_VERTICAL = 'VERT-CMN';
export const CUSTOMS_HOUSE_BROKERAGE = 'VERT-CHB';
// purpose related constants
export const FUMIGATION = 'PPS-FMG';
export const STOPOVER = 'PPS-STPOVR';
// scope related constants
export const ADMIN_SCOPE = 'admin:super';
export const GPS_DEVICE_VIEW_SCOPE = 'view:gps-device-mgt';
export const GPS_DEVICE_EDIT_SCOPE = 'edit:gps-device-mgt';
export const GPS_DEVICE_ADD_SCOPE = 'add:gps-device-mgt';
// aggregated map
export const NOT_ASSIGNED_TO_JOB = 'Not Assigned to a Job';

// workflow
export const LOCAL_HAULAGE_REPO = 'LF-RP-YP';
export const LOCAL_HAULAGE_DI = 'LF-DI-FTL';
export const LOCAL_HAULAGE_REMOVAL = 'LF-RM-PY';
export const LOCAL_HAULAGE_RELOCATION = 'LF-CRL';
export const FIELD_SERVICES = 'FS-FA';
export const LOCAL_HAULAGE = 'LF-RL';
export const DEFAULT_RESOURCE = 'UNKNOWN';
export const DISTRIBUTION_WF = 'LF-DI-FTL';
export const FOOT_TO_METER_MULTIPLIER = 0.3048;
export const DOMESTIC_WF = 'LF-DO-FTL';


// Routing values
export const APP_ROUTING = {
  main: '',
  taskFeed: {
    main: '/task-feed',
  },
  dashboard: {
    main: '/dashboard',
  },
  jobGroup: {
    main: '/job-group'
  },
  wizard: {
    main: '/wizard',
    jobFile: '/wizard/job-file',
    optimized: '/wizard/container-management'
  },
  // TODO: add other modules routes here
  auth: {
    main: '/auth',
    login: '/auth/login'
  },
  schedule: {
    main: '/schedule'
  },
  invoice: {
    main: '/invoice',
  },
  rateCardManager: {
    main: '/rate-card-manager'
  },
  runSheet: {
    main: '/run-sheet'
  },
  apiManagement: {
    main: '/api-management'
  },
  reports: {
    main: '/reports',
    jobMater: '/reports/job-master',
    downloads: '/reports/download',
  },
  organizationsManager: {
    main: '/organizations-manager/organizations/general-details'
  },
  aggregatedJPM: {
    main: '/aggregated-jpm',
    map: '/aggregated-jpm/map'
  },
  contracts: {
    main: '/contract'
  },
  fuel: {
    main: '/fuel'
  },
  quotation: {
    main: '/quotation',
  },
  controlTower: {
    main: '/control-tower'
  },
  orderManagement: {
    main: '/order-management'
  },
  contractManagement: {
    main: '/contract-management'
  }
}
