import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { JobFile } from '@core/models/job-file';
import { SharedState } from '@core/store/shared.state';
import { Select } from '@ngxs/store';
import { JobGroupJobOperation } from '@shared/models/job-group-job-operation.model';
import { Pagination } from '@shared/models/pagination.model';
import { EntityMap } from '@shared/models/types';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'hmt-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator: MatPaginator;

  @Input() jobs: JobFile[]  = [];
  @Input() tickedJobIdsMap: EntityMap<string, string> = {};
  @Input() title = '';
  @Input() paginationData: Pagination;
  @Input() selectedJobIndex;
  @Input() visibleJobsCount = 7; // this can be change as needed
  @Input() headerBoarder = true;
  @Input() enableOptions = true;
  @Input() enableMultiSelect = true;
  @Input() logosMap: EntityMap<string, string> = {};

  @Output() selectJobFile: EventEmitter<JobGroupJobOperation> = new EventEmitter();
  @Output() paginate: EventEmitter<PageEvent> = new EventEmitter();
  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() moveJobFIle: EventEmitter<JobGroupJobOperation> = new EventEmitter();
  @Output() terminateJobFIle: EventEmitter<JobGroupJobOperation> = new EventEmitter();
  @Output() renameJobFIle: EventEmitter<JobGroupJobOperation> = new EventEmitter();
  @Output() tickJobFile: EventEmitter<JobGroupJobOperation> = new EventEmitter();

  @Select(SharedState.getSkeletonLoaderStatus) loading$: Observable<boolean>;

  jobFileSearchForm: FormGroup;
  unsubscribe: Subject<void> = new Subject();
  numbers = Array.from({length: 10}, (_, i) => i + 1);

  constructor() { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.jobFileSearchForm = new FormGroup({
      searchText: new FormControl('')
    });

    this.jobFileSearchForm.get('searchText')
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        startWith(''),
        distinctUntilChanged(),
        debounceTime(500),
        tap(searchText => this.search.emit(searchText))
      )
      .subscribe();
  }

  clearSearch(): void {
    this.jobFileSearchForm.patchValue({searchText: ''});
  }

  clearPaginator(): void {
    this.paginator.pageIndex = 0;
  }

  onSelectJob(job, index): void {
    this.selectJobFile.emit({job, index});
  }

  onTickJobFile(job, index): void {
    this.tickJobFile.emit({job, index});
  }

  tickChange({checked}, job, index) {
    this.tickJobFile.emit({job, index, checked});
  }

  onPaginate(pageEvent: PageEvent) {
    this.paginate.emit(pageEvent);
  }

  moveTo(job, index): void {
    this.moveJobFIle.emit({job, index});
  }

  terminate(job, index): void {
    this.terminateJobFIle.emit({job, index});
  }

  rename(job, index): void {
    this.renameJobFIle.emit({job, index});
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
