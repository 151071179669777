import {TabType} from '../../models/enums/invoice-state.enum';
import {ApprovalStateType} from '../../models/enums/approval-state.enum';
import {OverdueStateType} from '../../models/enums/overdue-state.enum';
import {Invoice} from '@shared/models/invoice/invoice.model';
import {CreateInvoiceInput} from '@shared/models/invoice/create-invoice-input.model';
import {InvoiceType} from '../../models/enums/invoice-type.enum';
import {ContractType} from '@shared/models/enums/rate-sheet-contract-type.enum';
import { ReadyToInvoice } from '../../models/ready-to-invoice';
import {UpdateVCState} from '../../models/update-vc-state.model';
import {Expense} from '../../models/expense.model';
import { InvoicePageStatusFilter } from '../../models/invoice-page-status.model';
import { InvoiceDownloadType } from '../../models/enums/invoice-download-type.enum';

export const NEW_INVOICE_ACTION_PREFIX = '[Invoice]';

export class GetCustomersIdsAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get customer ids`;

  constructor(public fromDate: Date, public toDate: Date, public orgId: string) {
  }
}

export class GetSupplierIdsAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get supplier ids`;

  constructor(public fromDate: Date, public toDate: Date, public orgId: string) {
  }
}

export class GetOrgDetailsAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get customers`;

  constructor(public orgIds: string[]) {
  }
}

export class GetLoadsByJobFileAndCompletedAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get loads by job file and completed`;
  constructor(
    public orgId: string,
    public createdTo: string,
    public jobRefId: string,
    public isCompleted: boolean,
    public pageNo: number,
    public pageSize: number,
    public invoiceType: InvoiceType
  ) {
  }
}

export class GetLoadsForJobFileAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get loads by job file`;
  constructor(
    public orgId: string,
    public jobRefId: string,
    public pageNo: number,
    public pageSize: number
  ) {
  }
}

export class SelectAllLoadsAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Select all loads`;
  constructor(
    public orgId: string,
    public jobRefId: string,
    public isCompleted: boolean,
    public checked: boolean
  ) {
  }
}

export class SelectAllLoadsActionByOrg {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Select all loads by org`;
  constructor(
    public orgId: string,
    public createdTo: string,
    public isCompleted: boolean,
    public checked: boolean,
    public searchKey: string,
    public invoiceType: InvoiceType,
    public fromDate: Date,
    public toDate: Date
  ) {
  }
}

export class RemoveSelectedLoadsAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Remove selected loads`;

  constructor() {
  }
}

export class SelectLoadAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Select load`;
  constructor(
    public jpmId: string,
    public jobRefId: string,
    public checked: boolean
  ) {
  }
}

export class GetInvoiceAbleJobsAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get invoice able jobs`;

  constructor(
    public orgId: string,
    public createdTo: string,
    public pageNo: number,
    public pageSize: number,
    public searchKey: string,
    public invoiceType: InvoiceType,
    public fromDate: Date, public toDate: Date
  ) {
  }
}

export class GetNoOfCompletedLoadsForCreatedToAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get completed loads count for createdTo`;
  constructor(
    public orgId: string,
    public createdToIds: string[],
    public isCompleted: boolean,
    public invoiceType: InvoiceType,
    public fromDate: Date,
    public toDate: Date,
  ) {
  }
}

export class CreateInvoiceAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Create invoice`;

  constructor(public createInvoiceInput: CreateInvoiceInput) {
  }
}

export class UpdateInvoiceAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Update invoice`;

  constructor(public invoice: Invoice) {
  }
}

export class UpdateReadyToInvoiceAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Update Ready to invoice`;

  constructor(public readyToInvoice: ReadyToInvoice) {
  }
}

export class ChangeCurrencyAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Change invoice currency`;

  constructor(public invoice: Invoice) {
  }
}

export class RemoveLoadAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Remove load`;

  constructor(public id: string, public invoiceType: InvoiceType, public invoiceId: string) {
  }
}

export class RemoveAllLoadsAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Remove all loads`;

  constructor(public invoiceId: string, public invoiceType: InvoiceType) {
  }
}

export class FindByInvoiceTypeAndApprovalTypeAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Find invoice by invoice type and approval type`;

  constructor(
    public orgId: string,
    public tabType: TabType,
    public approvalType: ApprovalStateType,
    public pageNo: number,
    public pageSize: number,
    public invoiceType: InvoiceType,
    public fromDate: Date,
    public toDate: Date,
    public searchKey: string = '',
    public createdTo?: string[],
    public all: boolean = false
  ) {
  }
}

export class FindInvoiceByIdAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Find invoice by id`;

  constructor(public id: string) {
  }
}

export class FindByInvoiceTypeAndOverdueTypeAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Find invoice by invoice type and orverdue type`;

  constructor(
    public orgId: string,
    public tabType: TabType,
    public overdueState: OverdueStateType,
    public pageNo: number,
    public pageSize: number,
    public invoiceType: InvoiceType,
    public fromDate: Date,
    public toDate: Date,
    public searchKey: string = ''
  ) {
  }
}

export class GetAllTaxByCountryAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get taxes by country`;

  constructor(public countryCode: string) {
  }
}

export class LoadRateCardsAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Fetch Rate Cards`;

  constructor(public orgId: string, public contractType: ContractType, public organizationId: string, public searchKey: string) {
  }
}

export class ChangeRateCardForJobAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Change rate card`;

  constructor(public jpmId: string, public createdTo: string, public rateCardId: string) {
  }
}

export class UploadPdfAndGetId {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Download Invoice Pdf`;
  constructor(public orgId: string, public invoiceId: string) {
  }
}

export class GetPreSignedFile {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} get pre signed file`;
  constructor(public fileId: string) {
  }
}

export class FindAllCurrencyAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} find all currency`;
  constructor() {
  }
}

export class ResetInvoiceState {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Reset invoice state`;
}

export class ResetInvoiceMainState {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Reset invoice main state`;
}
export class GetRateCardsByIds {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get rate cards by ids`;
  constructor(public rateCardIds: string[]) {
  }
}

export class GetInvoiceLoadsLimit{
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get organization invoice loads limit`;
  constructor(public orgId: string) {
  }
}

export class FindCommitmentContractOrganizations{
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get commitment org ids`;
  constructor(public fromDate: Date, public toDate: Date, public orgId: string, public contractType: ContractType) {
  }
}

export class FindCommitmentInvoicesForOrgAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Get organization invoices`;
  constructor(
    public orgId: string,
    public organizationId: string,
    public invoiceType: InvoiceType,
    public pageNo: number,
    public pageSize: number,
    public fromDate: Date,
    public toDate: Date,
    public searchKey: string
  ) {
  }
}

export class ChangeInvoicedStateAction{
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Change Invoiced State`;
  constructor(public updateVCStates: UpdateVCState[]) {
  }
}

export class SelectInvoicesAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Select invoice action`;
  constructor(public invoiceIds: string[]) {
  }
}

export class UpdateFuelRateInvoicedStateAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Change Invoiced State for fuel rate invoice`;
  constructor(public invoiceIds: string[], public invoiceType: InvoiceType) {
  }
}

export class UpdateExpensesAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Update Additional Cost`;

  constructor(public id: string, public expenses: Expense[]) {
  }
}

export class UpdateInvoicePageStatusAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Update Invoice Page Status`;

  constructor(public invoicePageStatus: InvoicePageStatusFilter, public tabType: TabType, public invoiceType: InvoiceType) {
  }
}

export class LoadCustomersAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Load Customers`;
  constructor(public searchKey: string) {}
}


export class DownloadInvoicesAction {
  static readonly type = `${NEW_INVOICE_ACTION_PREFIX} Download Detailed invoices `;
  constructor(public downloadType: InvoiceDownloadType,public selectAll: boolean, public selectedInvoices?: string[],
   public excludeIds?: string[], public toDate?: Date, public fromDate?: Date) {}
}
