import { JobFileFilterRequest } from './../../modules/reporting/models/job-file-filter-request.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';

import {JobFile, JobFilePaginationRequest} from '../models/job-file';
import {API} from '@configs/api.config';
import {ContainerSchedule} from '@shared/models/container-schedule';
import {PaymentTerm} from '@shared/models/payment-term.model';
import {WorkOrderDefinition} from '@control-tower/models/work-order/work-order-definition.model';
import {getWorkOderDefinitions} from '@control-tower/test-data/jpm.test';
import {map, tap} from 'rxjs/operators';
import {CustomerInfo} from '@control-tower/models/customer-info.model';
import {JobSummary} from '../../modules/wizard/models/job-summary.model';
import {EntityMap} from '@shared/models/types';
import {CategorizedLocations} from '@quotation/models/categorized-locations';
import {PreSignedUrl} from "@control-tower/models/jpm/pre-signed-url";
import {RequiredDocumentType} from '../../modules/wizard/models/enums/required-document-type.enum';
import { FileInfo } from '@shared/models/file-info';
import {Pageable} from "@shared/models/pageable.model";
import {User} from "@core/models/user.model";
import {CollaboratorsInvitationModel} from "../../modules/wizard/models/collaborators-invitation.model";
import {Collaborator} from "../../modules/wizard/models/collaborator.model";
import { DistributionWorkflowData } from 'app/modules/wizard/models/distribution-workflow-data.model';

@Injectable({
  providedIn: 'root'
})
export class JobFileService {

  constructor(
    private readonly http: HttpClient
  ) {

  }

  getJobFiles(data: JobFilePaginationRequest): Observable<any> {
    return this.http.post(API.jobFile.getJobFiles, data);
  }

  getJobFileSummaries(data: JobFilePaginationRequest): Observable<any> {
    return this.http.post(API.jobFile.getJobFileSummaries, data);
  }

  getJobFileByOrgIdAndJobRefId(orgId: string, jobFileId: string): Observable<JobFile> {
    const url = API.jobFile.getJobFileByOrgIdAndJobRefId.format([orgId, jobFileId]);
    return this.http.get<JobFile>(url);
  }

  getJobFileByJobRefId(jobFileId: string): Observable<JobFile> {
    const url = API.jobFile.getJobFileByJobRefId.format([jobFileId]);
    return this.http.get<JobFile>(url);
  }

  getOrderRefId(workOrderId: string){
    const url = API.jobFile.getOrderRefNumbers.format([workOrderId]);
    return this.http.get(url);
  }

  assignAndSchedule(orgId: string, jobRefId: string, order: string): Observable<ContainerSchedule[]> {
    const url = API.jobFile.assignAndSchedule.format([orgId, jobRefId, order]);
    return this.http.get<ContainerSchedule[]>(url);
  }

  addContractedRateToJobFile(paymentTerm: PaymentTerm, orgId: string, jobRefId: string, rateCardId: string, order: string, generatedBy: string, loadRateCard?: { [key: string]: string; }){
    const contractedRate = {
      rateCardId,
      paymentTerm,
      generatedBy,
      order,
      loadRateCard
    };
    const url = API.jobFile.contractedRate.format([orgId, jobRefId, order]);
   return this.http.post(url, contractedRate);
  }

  getWorkOrderDefinitions(): Observable<WorkOrderDefinition[]> {
    // const url = API.jobFile.getJobFileByOrgIdAndJobRefId.format([orgId, jobFileId]);
    // return this.http.get<JobFile>(url);
    return of(getWorkOderDefinitions(5));
  }

  downloadJobSummary(orgId: string , jobRefId: string, order: string){
    const url = API.jobFile.downloadJobSummary.format([orgId, jobRefId, order]);
    return this.http.get(url, {responseType: 'blob'}).pipe(
      map((res) => new Blob([res], {type: 'application/pdf'}))
    );
  }

  getPDFFileData(url: string) {
    return this.http.get(url, {responseType: 'blob'})
      .pipe(
        map((res) => new Blob([res], {type: 'application/pdf'}))
      );
  }

  getJobSummaryPDFFileInfo(orgId: string , jobRefId: string, order: string): Observable<FileInfo> {
    const url = API.jobFile.downloadJobSummary.format([orgId, jobRefId, order]);
    return this.http.get<FileInfo>(url)
  }

  getPdfPreSIgnedUrl(fileId: string) {
    const url = API.fileHandelar.getPreSignedUrl.format([fileId]);
    return this.http.get<PreSignedUrl>(url);
  }

  generateJobSummaryAsync(orgId: string , jobRefId: string, order: string){
    const url = API.jobFile.generateJobSummaryAsync.format([orgId, jobRefId, order]);
    return this.http.get(url);
  }

  generateCollaboratorsAsync(orgId: string , jobRefId: string, order: string){
    const url = API.jobFile.generateCollaboratorsAsync.format([orgId, jobRefId, order]);
    return this.http.get(url);
  }

  generateJobSummary(orgId: string, jobRefId: string, order: string){
    const url = API.jobFile.downloadJobSummary.format([orgId, jobRefId, order]);
    return this.http.get(url);
  }

  generateQuotationPDF(orgId: string, jobRefId: string, order: string){
    const url = API.quotation.generateSavePDF.format([orgId, jobRefId, order]);
    return this.http.get(url);
  }

  generateContractPDF(orgId: string, jobRefId: string, order: string){
    const url = API.smartContract.generateAndSavePDF.format([orgId, jobRefId, order]);
    return this.http.get(url);
  }

  jobSendForApproval(orgId: string, jobRefId: string, order: string): Observable<boolean> {
    const url = API.jobFile.jobSendForApproval.format([orgId, jobRefId, order]);
    return this.http.post<boolean>(url, null);
  }

  getCustomerInfoByCustomerId(customerId: string): Observable<CustomerInfo> {
    const url = API.organization.getOrganization.format([customerId]);
    return this.http.get<CustomerInfo>(url);
  }

  getJobSummary(orgId: string, jobFileId: string, order: string): Observable<JobSummary> {
    const url = API.jobFile.getJobSummary.format([orgId, jobFileId, order]);
    return this.http.get<JobSummary>(url);
  }

  finalizeJobFile(orgId: string, jobRefId: string, order: string): Observable<JobFile> {
    const url = API.jobFile.finalizeJobFile.format([orgId, jobRefId, order]);
    return this.http.get<JobFile>(url);
  }

  retrieveCategorizedLocationNames(orgId: string, jobFileId: string): Observable<EntityMap<string, CategorizedLocations[]>> {
    const url = API.jobFile.retrieveCategorizedLocationNames.format([orgId, jobFileId]);
    return this.http.get<EntityMap<string, CategorizedLocations[]>>(url);
  }

  saveWizardRowData(orgId: string, jobRefId: string, rowData: EntityMap<string, string>): Observable<JobFile> {
    const url = API.jobFile.saveWizardRowData.format([orgId, jobRefId]);
    return this.http.post<JobFile>(url, rowData);
  }

  saveVehicleCapacitiesInWizard(orgId: string, jobRefId: string, vehicleCapacities: EntityMap<string, {[vehicleType: number]: number}>): Observable<JobFile> {
    const url = API.jobFile.saveWizardVehicleCapacities.format([orgId, jobRefId]);
    return this.http.post<JobFile>(url, vehicleCapacities);
  }

  saveDistributionWorkflowData(orgId: string, jobRefId: string, distributionWorkflowData: DistributionWorkflowData): Observable<JobFile> {
    const url = API.jobFile.saveWizardDistributionWorkflowData.format([orgId, jobRefId]);

    return this.http.post<JobFile>(url, distributionWorkflowData);
  }

  getPackingListExcel(orgId: string, jobRefId: string, containerId: string) {
    const url = API.jobFile.downloadPackingList.format([orgId, jobRefId, containerId]);
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      map((response) => {
        const blob = new Blob([response.body], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'packing-list.xlsx';
        if (contentDisposition) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        return {
          blob,
          filename,
          headers: response.headers
        };
      })
    );
  }

  getAllPackingListsZip(orgId: string, jobRefId: string) {
    const url = API.jobFile.downloadPackingListZip.format([orgId, jobRefId]);
    return this.http.get(url, {responseType: 'blob'})
      .pipe(
        map((res) => new Blob([res], {type: 'application/zip'}))
      );
  }

  uploadWizardAttachments(files: File[], urlSuffix: string): Observable<PreSignedUrl[]> {
    const data = new FormData();
    for (const file of files) {
      data.append('files', file, file.name);
    }
    const url = `${API.jobFile.uploadWizardAttachments}${urlSuffix}`;
    return this.http.post<PreSignedUrl[]>(url, data);
  }

  removeWizardAttachment(orgId: string, jobRefId: string, attachmentId: string) {
    const url = API.jobFile.removeWizardAttachment.format([orgId, jobRefId, attachmentId]);
    return this.http.delete(url);
  }

  uploadWizardRequiredDocument(file: File, urlSuffix: string): Observable<PreSignedUrl[]> {
    const data = new FormData();
    data.append('file', file, file.name);
    const url = `${API.jobFile.uploadWizardAttachments}${urlSuffix}`;
    return this.http.post<PreSignedUrl[]>(url, data);
  }

  modifyUrlReplacingParameter(url: string, orgId: string, jobRefId: string) {
    const preDefineOrgId = /{orgId}/gi;
    const preDefineJobRefId = /{jobRefId}/gi;
    return url
      .replace(preDefineOrgId, orgId)
      .replace(preDefineJobRefId, jobRefId);
  }

  modifyUploadRequiredDocumentApiUrl(url: string, orgId: string, jobRefId: string, type: RequiredDocumentType) {
    const preDefineOrgId = /{orgId}/gi;
    const preDefineJobRefId = /{jobRefId}/gi;
    const preDefineType = /{type}/gi;
    return url
      .replace(preDefineOrgId, orgId)
      .replace(preDefineJobRefId, jobRefId)
      .replace(preDefineType, type);
  }

  fetchUsersByScopes(scopes: string[], pageNum: number, pageSize: number, searchKey?: string): Observable<Pageable<User>> {
    let url = API.organization.getOrgUserByScopes.format([`${pageNum}`, `${pageSize}`]);

    if (searchKey) {
      url = `${url}&searchKey=${searchKey}`;
    }
    return this.http.post<Pageable<User>>(url, scopes);
  }

  inviteCollaborators(collaboratorsInvitationModel: CollaboratorsInvitationModel): Observable<JobFile> {
    const url = API.jobFile.inviteCollaborators;
    return this.http.post<JobFile>(url, collaboratorsInvitationModel);
  }

  removeCollaborator(orgId: string, jobRefId: string, collaboratorId: string): Observable<JobFile> {
    const url = API.jobFile.removeCollaborator.format([orgId, jobRefId, collaboratorId]);
    return this.http.get<JobFile>(url);
  }

  deleteShipmentOrderSpecFromJobFile(jobRefId: string, shipmentOrderId: string) {
    const url = API.jobFile.deleteShipmentOrderFromJobFile.format([jobRefId, shipmentOrderId]);
    return this.http.post(url, {});
  }

  deleteAllShipmentOrderSpecsFromJobFile(jobRefId: string) {
    const url = API.jobFile.deleteAllShipmentOrdersFromJobFile.format([jobRefId]);
    return this.http.post(url, {});
  }

  reArrangeLocationServices(orgId: string, jobRefId: string, containerId: string, reArrangedLocationIds: string[], prevIndex: number, currIndex: number) {
    const url = API.jobFile.reArrangeLocationOrder.format([orgId, jobRefId, containerId, prevIndex.toString(), currIndex.toString()]);
    return this.http.post(url, reArrangedLocationIds);
  }

  saveOtherReferenceNumber(orgId :string, jobRefId: string, containerId: string, locationId: string, serviceId: string, workOrderId: string, refNumbers: string []) {
    if (workOrderId == null){
      workOrderId = 'wizard';
    }
    const url=API.jobFile.saveOtherReferenceNumbers.format([orgId, jobRefId, containerId, locationId, serviceId, workOrderId]);
    return this.http.post(url, refNumbers);
  }

  deleteOtherReferenceNumber(orgId :string, jobRefId: string, containerId: string, locationId: string, serviceId: string, workOrderId: string, refNumber: string){
    const url = API.jobFile.deleteOtherReferenceNumber.format([orgId, jobRefId, containerId, locationId, serviceId, workOrderId]);
    return this.http.delete(url, { params: { refNumber } });
  }

  saveOrderReferenceNumber(orgId :string, jobRefId: string, containerId: string, locationId: string, serviceId: string, workOrderId: string, refNumbers: string[] ){
    const url=API.jobFile.saveOrderReferenceNumbers.format([orgId, jobRefId, containerId, locationId, serviceId]);
    return this.http.post(url, refNumbers);
  }

  saveRemark(orgId: string, jobRefId: string, containerId: string, remark: string) {
    const url = API.jobFile.saveRemark.format([orgId, jobRefId, containerId]);
    return this.http.post(url, remark);
  }

  saveRemarks(orgId: string, jobRefId: string, containerId: string, remarks: string[]) {
    const url = API.jobFile.saveRemarks.format([orgId, jobRefId, containerId]);
    return this.http.post(url, remarks);
  }

  deleteRemark(orgId: string, jobRefId: string, containerId: string, indexes: number[]) {
    const url = API.jobFile.deleteRemark.format([orgId, jobRefId, containerId]);
    return this.http.delete(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: indexes
    } as any);
  }

  getRemarks(orgId: string, jobRefId: string, containerId: string): Observable<string[]> {
    const url = API.jobFile.getRemarks.format([orgId, jobRefId, containerId]);
    return this.http.get<string[]>(url);
  }
}
