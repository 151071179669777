<div class="create-job-file-wrapper col">
    <div class="create-job-file col">
        <div class="header row">
          <div class="title bold">
              Create Job
          </div>
          <div class="close col va-c" (click)="close()">
            <mat-icon>close</mat-icon>
          </div>
        </div>
        <div class="content col">
            <form [formGroup]="jobFileForm">
              <div class="row">
                <div class="input-wrapper col">
                  <mat-form-field class="full-width"  appearance="outline">
                    <mat-label>Job File Name*</mat-label>
                    <input type="text"
                      matInput
                      formControlName="jobTitle"
                      placeholder="Example Job File One"
                      class="lane-value">
                  </mat-form-field>
                  <!-- <div class="error-required">
                    <mat-error *ngIf="jobFileForm?.get('jobTitle')?.hasError('required') && jobFileForm?.get('jobTitle')?.touched">Name Required</mat-error>
                  </div> -->
                </div>

                <div class="input-wrapper row">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Select Group*</mat-label>
                    <mat-icon matSuffix>arrow_drop_down</mat-icon>
                    <input type="text"
                          matInput
                          formControlName="jobGroup"
                          placeholder="Search the Job Group"
                          [matAutocomplete]="jobRefIdAutoComplete" [readonly]="data.isJobGroupReadonly">
                    <!-- <div>
                      <i class="material-icons align-arrow-right">arrow_drop_down</i>
                    </div> -->
                    <mat-autocomplete #jobRefIdAutoComplete="matAutocomplete" [displayWith]="displayWithGroupId">
                      <mat-option class="select-label" *ngFor="let jobGroup of data.jobGroups | async"
                                  [value]="jobGroup">
                        {{jobGroup?.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <div class="add-job-group c-p" (click)="onAddJobGroup()">
                    <div class="icon">
                      +
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- <div class="input-wrapper col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Job Reference Number</mat-label>
                    <input type="text"
                      matInput
                      formControlName="jobRefNumber"
                      placeholder="Enter Job Reference Number"
                      class="lane-value">
                  </mat-form-field>
                </div> -->
                <div class="input-wrapper col customer-ref-num">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Customer's Reference Number</mat-label>
                    <input type="text"
                      matInput
                      formControlName="customerReferenceNo"
                      placeholder="Enter Customer's Reference Number"
                      class="lane-value">
                  </mat-form-field>
                  <!-- <div class="error-required">
                    <mat-error *ngIf="jobFileForm?.get('customerRefNumber')?.hasError('required') && jobFileForm?.get('customerRefNumber')?.touched">Customer Ref Number Required</mat-error>
                  </div> -->
                </div>

                <div class="input-wrapper row">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Select Operation*</mat-label>
                    <mat-icon matSuffix>arrow_drop_down</mat-icon>
                    <input type="text"
                          matInput
                          formControlName="operation"
                          placeholder="Search the Job Group"
                          [matAutocomplete]="operationAutoComplete">
                    <mat-autocomplete #operationAutoComplete="matAutocomplete" [displayWith]="displayWithGroupId">
                      <ng-container *ngFor="let operation of data.operations | async">
                        <mat-option *ngIf="operation.id !== ''" class="select-label" [value]="operation">
                          {{operation?.name}}
                        </mat-option>
                      </ng-container>
                      
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>

              <div class="internal-collaborators row va-c mt-10">
                <div class="collaborator row va-c" *ngFor="let collaborator of selectedCollaborators; let i = index;">
                  <div class="text">{{collaborator?.firstName}} {{collaborator?.lastName}}</div>
                  <div class="close row va-c ha-c c-p bold" (click)="removeCollaborator(i)">x</div>
                </div>
                <mat-form-field class="collaborators" >
                  <!-- <mat-label>Select Group</mat-label> -->
                  <!-- <mat-icon matSuffix>arrow_drop_down</mat-icon> -->
                  <input type="text"
                        matInput
                        formControlName="internalCollaborators"
                        [matAutocomplete]="internalCollaboratorsAutoComplete">

                  <mat-autocomplete (optionSelected)="onSelectCollaborator($event.option.value)" #internalCollaboratorsAutoComplete="matAutocomplete" [displayWith]="displayWithCollaborator">
                    <mat-option class="select-label" *ngFor="let collaborator of data.internalCollaborators | async"
                                [value]="collaborator">
                      {{collaborator?.firstName}} {{collaborator?.lastName}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="job-types col mt-20">
                <div class="title bold">
                  Select Your Job Type*
                </div>
                <div class="types">
                  <div class="type import col"
                    [ngClass]="{'selected': selectedJobType == jobType.IMPORT}"
                    (click)="selectJobType(jobType.IMPORT)">
                    <div class="icon">
                      <span class="hm-icon hm-icon-import"></span>
                    </div>
                    <div class="text bold">
                      Import
                    </div>
                  </div>
                  <div class="type export col"
                    [ngClass]="{'selected': selectedJobType == jobType.EXPORT}"
                    (click)="selectJobType(jobType.EXPORT)">
                    <div class="icon">
                      <span class="hm-icon hm-icon-export"></span>
                    </div>
                    <div class="text bold">
                      Export
                    </div>
                  </div>
                  <div class="type domestic col selected"
                    [ngClass]="{'selected': selectedJobType == jobType.DOMESTIC}"
                    (click)="selectJobType(jobType.DOMESTIC)">
                    <div class="icon">
                      <mat-icon>house</mat-icon>
                    </div>
                    <div class="text bold">
                      Domestic
                    </div>
                  </div>
                  <div class="type domestic col selected"
                       [ngClass]="{'selected': selectedJobType == jobType.DISTRIBUTION}"
                       (click)="selectJobType(jobType.DISTRIBUTION)">
                    <div class="icon">
                      <mat-icon>local_shipping</mat-icon>
                    </div>
                    <div class="text bold">
                      Distribution
                    </div>
                  </div>
                  <div class="type reposition col selected"
                    [ngClass]="{'selected': selectedJobType == jobType.REPOSITION}"
                    (click)="selectJobType(jobType.REPOSITION)">
                    <div class="icon">
                      <mat-icon>move_down</mat-icon>
                    </div>
                    <div class="text bold">
                      Repositioning
                    </div>
                  </div>
                  <div class="type relocation col selected"
                    [ngClass]="{'selected': selectedJobType == jobType.RELOCATION}"
                    (click)="selectJobType(jobType.RELOCATION)">
                    <div class="icon">
                      <mat-icon>move_up</mat-icon>
                    </div>
                    <div class="text bold">
                      Relocation
                    </div>
                  </div>
                  <div class="type removal col selected"
                    [ngClass]="{'selected': selectedJobType == jobType.REMOVAL}"
                    (click)="selectJobType(jobType.REMOVAL)">
                    <div class="icon">
                      <mat-icon>wrap_text</mat-icon>
                    </div>
                    <div class="text bold">
                      Removal
                    </div>
                  </div>
                </div>
              </div>

              <div [ngClass]="{'failed-validation': jobFileForm.get('loadType').value === '' }" class="load-types col mt-20" *ngIf="selectedJobType != jobType.RELOCATION && selectedJobType != jobType.REMOVAL && selectedJobType != jobType.REPOSITION">
                <div class="title bold">
                  Load Type*
                </div>
                <div class="types mt-10 row">
                  <mat-radio-group formControlName="loadType"  class="load-type-group" aria-label="Select a Load Type">
                    <mat-radio-button [disabled]="selectedJobType == jobType.DISTRIBUTION" value="{{loadType.FCL}}" class="load-type">FCL</mat-radio-button>
                    <mat-radio-button value="{{loadType.FTL}}" class="load-type">FTL</mat-radio-button>
                    <mat-radio-button [disabled]="true" value="{{loadType.LCL}}" class="load-type">LCL</mat-radio-button>
                    <mat-radio-button [disabled]="true" value="{{loadType.LTL}}" class="load-type">LTL</mat-radio-button>
                  </mat-radio-group>
                </div>
                <!-- <div class="error-required">
                  <mat-error *ngIf="jobFileForm?.get('loadType')?.hasError('required') && jobFileForm?.get('loadType')?.touched">Load Type Required</mat-error>
                </div> -->
              </div>
            </form>
        </div>
        <div class="footer row">
            <button mat-stroked-button color="warn" class="close-btn uppercase" (click)="close()">Close</button>
            <button mat-raised-button color="primary" class="create-btn uppercase" [disabled]="jobFileForm.invalid" (click)="createJobFile()">Create</button>
        </div>
    </div>
</div>
